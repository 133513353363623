import {LogoIcon} from "../client/components/logo"
import { PopupStepper } from '../client/ui'
import { Spacer } from "@geist-ui/core"
import { useRouter } from "next/router"
import steps from '../client/auth/steps'
import AuthWrapper from "../client/auth/wrapper"
import ClonerAPI from "../client/services/cloner-api"

const AuthPage = () => {

    const router = useRouter()
    const { query: { code, url, env } } = router
    const clonerAPI = new ClonerAPI(env)

    
    const callback = async data => {
        const {email, password} = data
        const {refreshToken} = !data.confirmedPassword ? await clonerAPI.login(email, password, code) : await clonerAPI.signup(email, password, code, data)
        const finalURL =  url + `?token=${refreshToken}`
        await router.push(finalURL ?? '/')
    }

    return <AuthWrapper>
        <LogoIcon style={{ width: "40px", height: "40px" }}></LogoIcon>
        <Spacer h={2}></Spacer>
        <PopupStepper style={{width: "100%"}} hideDismiss callback={callback} initialStep='start' steps={steps}>
        </PopupStepper>
    </AuthWrapper>
}

export default AuthPage