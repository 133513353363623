import { setUserCookies } from "../../auth";
import Network from "../network";

const API_URLS = {
    "development": "http://localhost:3000",
    "stage": "https://stage.cloner.co.za",
    "production": "https://app.cloner.co.za"
}

class ClonerAPI {

    constructor(environment) {
        this.environment = environment;
        this.apiUrl = API_URLS[environment] ?? API_URLS["production"];

        console.log("API URL IS", this.apiUrl)
    }

    async getAPIUrl() {
        return this.apiUrl;
    }

    async login(email, password, inviteCode) {

        console.log("API URL IS", this.apiUrl)
        
        const res = await Network.post(this.apiUrl + '/api/v1/auth/login', "", {
            email,
            password,
            code: inviteCode
        })

        console.log("RES IS", res)

        setUserCookies(res)

        return res
    }

    async signup(email, password, inviteCode, data) {

        const res = await Network.post(this.apiUrl + '/api/v1/auth/signup', "", { email, password, inviteCode, ...data })
        if (!res) throw new Error("Could not log in")

        setUserCookies(res)

        return res

    }

    async checkEmail(email) {
        const { userExists } = await Network.post(this.apiUrl + '/api/v1/auth/check', null, { email })
        return userExists
    }

    async refreshToken(refreshToken) {
        const res = await Network.post(this.apiUrl + '/api/v1/auth/refresh', null, { refreshToken })
        setUserCookies(res)
        return res
    }

    async logout() {
        Storage.local.remove('user')
        Storage.local.remove('refreshToken')
    }

}

export default ClonerAPI;