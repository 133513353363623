import { WrappedInput } from "../../components/auth"
import { Grid, Spacer } from "@geist-ui/core"
import { Link, Loader } from "../../ui"
import { PrimaryButton, Warning } from "../../ui"
import { useState } from "react"
import Network from "../../services/network"


const LoginStep = ({ onChange, data, onComplete, res }) => {

    const [loading, setLoading] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [error, setError] = useState(res)

    const handleComplete = async () => {
        try {
            await onComplete()
        } catch (e) {

        }
    }

    const resetPassword = async e => {
        e.preventDefault()
        setLoading(true)
        setEmailSent(false)
        setError()
        try {
            await Network.post('/api/v1/auth/reset-password/request', null, { email: data.email })
            setEmailSent(true)
        } catch (error) {
            setError("Could not reset password")
        }
        setLoading(false)
    }

    return <Grid direction="column">
        {error || res ? <>
            <Warning>{error ?? res}</Warning>
            <Spacer h={2} />
        </> : null}
        {loading ? <div style={{ textAlign: "center" }}>
            <Loader/>
        </div> : null}
        {emailSent ? <div style={{ textAlign: "center" }}>Email sent!</div> : null}
        {!emailSent && !error && !loading ? <>
        <input hidden value={data?.email} onChange={onChange} name='email' type='email' placeholder='Your Email' />
        <WrappedInput onComplete={handleComplete} onChange={onChange} password name='password' text='Enter your password' />
        <Spacer h={2} />
        <PrimaryButton style={{ background: "black" }} fullWidth onClick={handleComplete}>Log In</PrimaryButton>
        <Spacer />
        <div style={{ fontSize: "16px", textAlign: "center", color: "var(--color-primary-50)" }}>
            Forgot your password? You can <Link onClick={resetPassword} underline style={{ color: "var(--color-brand)", fontWeight: 500 }}>reset</Link> it.
        </div>
        </> : null}
    </Grid>
}

const onComplete = async (setStep, submit, n,) => {
    await submit(true)
}

const loginStep = {
    View: LoginStep,
    title: 'Welcome Back',
    utility: true,
    subtitle: "Nice to see you",
    onComplete,
    isValid: () => !!data['password']
}

export default loginStep