import { WrappedInput } from "../../components/auth"
import { Card, Loader, PrimaryButton } from '../../ui'

import { Grid, Spacer } from "@geist-ui/core"
import { useEffect } from "react"
import { useInvite } from '../../data/useInvite'
import { useRouter } from "next/router"
import { TeamProfile } from "../../core/team"

const TeamNameStep = ({ onChange, data, onComplete }) => {

    const { query: { code } } = useRouter()
    const { invite, inviteLoading } = useInvite(code)

    useEffect(() => {
        if (invite?.team) {
            onChange({ target: { name: 'teamName', value: invite.team.name } })
        }
    }, [invite])

    if (inviteLoading) return <Loader />
    const teamInvite = Object.keys(invite?.team ?? {}).length > 0

    if (!invite || !teamInvite) return <Grid direction="column">
        <WrappedInput onComplete={onComplete} value={data?.teamName} placeholder='My New Team' onChange={onChange} name="teamName" text='Team Name' />
        <Spacer h={2} />
        <PrimaryButton style={{ background: "black" }} onClick={onComplete} fullWidth>Create Account</PrimaryButton>
    </Grid>

    return <Grid.Container direction="column" justify="center" alignItems="center">
        {invite.user.name} has invited you to join their team
        <Spacer />
        <Card style={{ border: "var(--standard-border", boxShadow: "none", padding: "16px" }}>
            <TeamProfile team={invite.team} />
        </Card>
        <Spacer h={2}/>
        <PrimaryButton onClick={onComplete} fullWidth style={{background: "black"}}>
            Join Team
        </PrimaryButton>
    </Grid.Container>

}


const teamNameStep = {
    View: TeamNameStep,
    title: 'Your Team',
    subtitle: "Cloner is built for a team-based workflow",
    onComplete: (setStep, submit) => { submit() },
    actionText: 'Join Cloner',
    isValid: (data) => !!data['teamName']
}



export default teamNameStep