import {Grid, Spacer} from "@geist-ui/core"
import { WrappedInput } from "../../components/auth"
import { PrimaryButton } from "../../ui"
import Router from "next/router"
import { useState } from "react"
import ClonerAPI from "../../services/cloner-api"

const EmailStepView = ({ onChange, data, onComplete }) => {

    const [loading, setLoading] = useState(false)

    const hijacker = async (e) => {
        setLoading(true)
        await onComplete()
        setLoading(false)
    }

    return <Grid direction="column">
        <WrappedInput throwError onComplete={hijacker} value={data?.email} autocomplete='username' onChange={onChange} name='email' type='email' placeholder='Your Email' />
        <Spacer h={2} />
        <PrimaryButton style={{background: "black"}} loading={loading} fullWidth onClick={hijacker}>Continue</PrimaryButton>
    </Grid>
}

const onComplete = async (setStep, submit, { data, setData }) => {
    const code = Router.query?.code
    const env = Router.query?.env

    const clonerAPI = new ClonerAPI(env)
    const userExists = await clonerAPI.checkEmail(data?.email)

    if (userExists) setStep('login')
    else {
        if (!code) {
            setStep("start")
            throw new Error("You need an invite code to join Cloner ")
        } else setStep("name")
    }
}

const emailStep = {
    title: 'Welcome to Cloner',
    subtitle: "Enter your email address to continue.",
    isValid: (data) => !!data['email'],
    View: EmailStepView,
    onComplete,
}


export default emailStep