import useSWR, {useSWRConfig} from "swr"
import Network from "../services/network"

const useInvite = (id) => {
    
    const { mutate } = useSWRConfig()
    const { data, error } = useSWR(`/api/invites/${id}`, Network.get)

    const refreshInvite = async () => {
        await mutate(`/api/invites/${id}`)
    }

    return {
        invite: data,
        inviteLoading: !error && !data,
        inviteError: error,
        refreshInvite
    }
}


export {useInvite}