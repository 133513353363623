import passwordValidator from "password-validator"

import emailStep from "./1-email"
import nameStep from "./2-name"
import loginStep from "./2-login"
import passwordStep from "./3-password"
import teamNameStep from "./4-team-name"
import confirmationStep from "./5-confirmation"

import loadingStep from './loading'
import errorStep from './error'

const createValidator = () => {
    var schema = new passwordValidator();
    schema
        .is().max(100)
        .has().lowercase()
        .is().not().oneOf(['Passw0rd', 'Password123']);
    return schema
}

const steps = {
    "start": emailStep,
    "name": nameStep,
    "login": loginStep,
    "password": passwordStep,
    "organisationName": teamNameStep,
    "loading": loadingStep,
    "done": confirmationStep,
    "error": errorStep,
}

export default steps