import { Grid } from "@geist-ui/core"
import Image from "next/image"
import Skeleton from "react-loading-skeleton"
import styles from './profile.module.css'

const TeamProfile = ({ team }) => {

    const { name, location, logo } = team ?? {}
    const loading = !team


    return <Grid.Container alignItems='center' gap={2}>
        <Grid>
            {loading ? <Skeleton width={40} height={40}/> : 
            <Image width={40} height={40} className={styles.teamProfileImage} src={logo ?? "/placeholder.png"} alt="logo" />}
        </Grid>
        <Grid>
            <div className={styles.teamName}>{loading ? <Skeleton /> : name}</div>
            <div className={styles.teamProfileLocation}>{loading ? <Skeleton/> : location}</div>
        </Grid>
    </Grid.Container>
}

export default TeamProfile