import {Grid} from "@geist-ui/core"
import { useRouter } from "next/router"
import { PrimaryButton } from "../../ui"

const ConfirmationStep = ({ data }) => {
    const router = useRouter()
    const onComplete = () => {
        const { url } = router.query
        router.push(url ?? '/')
    }
    return <Grid direction="column">
        <PrimaryButton fullWidth onClick={onComplete} style={{background: "black"}}>
            Open App
        </PrimaryButton>
    </Grid>
}


const confirmationStep = {
    View: ConfirmationStep,
    title: 'All Set! Welcome to Cloner',
    subtitle: "We're stoked to have you on board.",
    actionText: "Open App",
}

export default confirmationStep