import {Spacer, Grid} from "@geist-ui/core"
import { WrappedInput } from "../../components/auth"
import { PrimaryButton } from "../../ui"

const NameStep = ({ onChange, data, onComplete }) => {
    return <Grid direction="column">
        <WrappedInput onComplete={onComplete} value={data?.firstName} onChange={onChange} name="firstName" text='First Name' />
        <Spacer />
        <WrappedInput onComplete={onComplete} value={data?.lastName} onChange={onChange} text='Last Name' name="lastName" />
        <Spacer h={2}/>
        <PrimaryButton style={{background: "black"}} fullWidth onClick={onComplete}>Next</PrimaryButton>
    </Grid>
}

const onComplete = (setStep) => { setStep("password") }

const nameStep = {
    View: NameStep,
    title: 'Join Cloner Grow',
    subtitle: "We can't wait to have you on board",
    onComplete,
    isValid: (data) => !!data['firstName'] && !!data['lastName']
}

export default nameStep