import {Warning} from "../../ui"

const ErrorStep = ({ res }) => {
    return <Warning text={res} />
}

const errorStep = {
    View: ErrorStep,
    title: 'Uh oh',
    utility: true,
    subtitle: "That's an error",
    onComplete: async (setStep, submit) => {
        if (e) e.preventDefault()
        setStep("start")
    },
    isValid: true
}

export default errorStep